import { Context, createContext, useContext, useEffect, useState } from 'react'
import { createUAParser, getDeviceType } from '../../hooks/useDeviceInfo'
import { ItemStorage } from '../../newPackages/StorageProviders/localStorageProvider'
import { ApiPlatform, Config } from './Config'

export type ConfigService = {
  appVersion: string
  nativeAppVersion: string
  apiEnvironment: string
  currentConfig: Config
}

export const ConfigServiceContext = createContext<ConfigService | undefined>(undefined)

type DefinedContext<T> = Exclude<T, undefined>
export function useContextUnconditionally<T>(context: Context<T>): DefinedContext<T> {
  const contextValue = useContext(context)
  if (contextValue === undefined) {
    console.error('Context was used before data was fetched.  This should not be possible.  Check render method')
  }
  return contextValue as DefinedContext<T>
}

const configStorage = new ItemStorage<Config | undefined>('config', undefined)

// https://server.com/configapi/<LASTKNOWN CHECKSUM>/X-APIversion/X-brand/X-appPlatform/X-appModel/X-appVersion/X-appOSversion/X-appLanguage
type ConfigURLProps = {
  checksum: string
  appVersion: string
  apiEnvironment: string // 0,6,8
  apiPlatform: ApiPlatform
}

const getBrowserVersion = (): string => {
  const ua = createUAParser()
  // get browser version
  const browser = ua.getBrowser()
  return browser?.version ?? '1.0.0'
}
const buildConfigURL = ({ checksum, appVersion, apiEnvironment, apiPlatform }: ConfigURLProps) => {
  const url = new URL('https://configapi.ballysports.com')
  url.pathname = [
    checksum,
    '1.0.6', // Config API Version
    'ballysports', // Brand
    apiPlatform, // Platform
    getDeviceType(), // Model
    `${appVersion}.${apiEnvironment}`, // App Version
    getBrowserVersion(), // Browser/OS Version
    'en-US', // Language
  ].join('/')

  return url.toString()
}

type ConfigServiceProviderProps = {
  appVersion: string
  nativeAppVersion: string

  /** The ConfigAPI environment to use */
  apiEnvironment: string
  apiPlatform: ApiPlatform
}

export const ConfigServiceProvider = ({
  children,
  nativeAppVersion,
  appVersion,
  apiEnvironment,
  apiPlatform,
}: React.PropsWithChildren<ConfigServiceProviderProps>) => {
  const [config, setConfig] = useState<ConfigService | undefined>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const checksum = 'default'

      const response = await fetch(buildConfigURL({ checksum, appVersion, apiEnvironment, apiPlatform }))
      const config = await response.json()
      configStorage.setItem(config)
      setConfig({
        appVersion,
        nativeAppVersion,
        apiEnvironment,
        currentConfig: { ...config, apiPlatform },
      })
    }
    fetchData()
    // These dependency array values all come from clientRuntimeConfig and should never change
  }, [nativeAppVersion, appVersion, apiEnvironment, apiPlatform])

  if (config) {
    return <ConfigServiceContext.Provider value={config}>{children}</ConfigServiceContext.Provider>
  } else {
    return <></>
  }
}
